/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-14 17:34:05
 * @Module: 收到的赞 
 */
 <template>
  <div class="box"
       v-loading="loading">
    <div class="item hover"
         v-for="(item,index) in list"
         @click="()=>to_detail(item)"
         :key="index">
      <div class="item-l">
        <div @click.stop="()=>to_personal(item)">
          <el-avatar :size="48"
                     :src="item.user_avatar"></el-avatar>
        </div>
        <div class="item-l-content">
          <div class="item-l-content-t">
            <span class="item-l-content-t-1">{{item.user_nick_name}} </span>
            <span class="item-l-content-t-2">赞了我的动态</span>
          </div>
          <div class="item-l-content-b">{{item.created_at}}</div>
        </div>
      </div>
      <div class="item-r">
        <el-image style="width: 60px;height: 60px;border-radius: 2px;"
                  :src="item.cover"
                  v-show="item.cover" />
      </div>
    </div>
    <el-pagination layout="prev, pager, next"
                   style="display: flex;justify-content: center;"
                   v-show="pagination.page"
                   :current-page="pagination.page"
                   @current-change="currentChange"
                   :page-size="pagination.pageSize"
                   hide-on-single-page
                   :total="pagination.total">
    </el-pagination>
    <no-data v-show="pagination.total==0&& !loading">暂无评论</no-data>
  </div>
</template>
 <script>
import noData from "@/components/noData"
export default {
  components: {
    noData
  },
  data () {
    return {
      loading: true,
      list: [],
      pagination: {
        total: 0,
        pageSize: 20,
        page: null
      },
    };
  },
  mounted () {
    this._get_list()
  },
  methods: {
    currentChange (page) {
      this._get_list(page)
    },
    async _get_list (page = 1) {
      this.loading = true
      const { status, info, data, meta } = await this.$api.userLikeListApi({ page })
      this.loading = false
      if (status == 200) {
        this.list = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        this.pagination.page = meta.pagination.current_page
      } else {
        this.$message.error(info)
      }

    },
    to_detail ({ likeable: { uuid, moment_type: type } }) {
      this.$store.dispatch("homepage/toDetail", { type, uuid })
    },
    to_personal ({ user_uuid: uuid }) {
      this.$store.dispatch("homepage/toPersonal", { uuid })
    }
  },
};
 </script>
 <style lang='scss' scoped>
@import "../style.scss";
</style>